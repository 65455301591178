<app-header id="top" (idElementEmitter)="onClickScroll($event)"></app-header>

<!-- 
	=============================================
				Theme Main Banner
	============================================== 
-->
<div id="theme-main-banner" class="banner-one">
    <div data-src="assets/images/home/top-2.jpg">
        <div class="camera_caption">
            <div class="container">
                <p class="wow fadeInUp animated">CONTRATANDO ACTITUD Y ENTRENANDO LA HABILIDAD</p>
                <h1 class="wow fadeInUp animated" data-wow-delay="0.2s">TU ÉXITO ES <br>NUESTRA FÓRMULA</h1>
                <a routerLink="/home" fragment="contacto" class="theme-button-one wow fadeInUp animated" data-wow-delay="0.39s">¡CONTACTA HOY!</a>
            </div>
            <!-- /.container -->
        </div>
        <!-- /.camera_caption -->
    </div>
    <div data-src="assets/images/home/top-1.jpg">
        <div class="camera_caption">
            <div class="container">
                <p class="wow fadeInUp animated">RECURSOS HUMANOS ES LO QUE HACEMOS, ES LO QUE</p>
                <h1 class="wow fadeInUp animated" data-wow-delay="0.2s">MEJORAMOS PARA QUE <br> TU NEGOCIO FUNCIONE</h1>
                <a routerLink="/home" fragment="contacto" class="theme-button-one wow fadeInUp animated" data-wow-delay="0.39s">¡CONTACTA HOY!</a>
            </div>
            <!-- /.container -->
        </div>
        <!-- /.camera_caption -->
    </div>
    <div data-src="assets/images/home/top-3.jpg">
        <div class="camera_caption">
            <div class="container">
                <p class="wow fadeInUp animated">REDUCE TU ROTACIÓN, MEJORA LA SELECCIÓN DE PERSONAL</p>
                <h1 class="wow fadeInUp animated" data-wow-delay="0.2s">NOSOTROS LO <br> HACEMOS POR TI</h1>
                <a routerLink="/home" fragment="contacto" class="theme-button-one wow fadeInUp animated" data-wow-delay="0.39s">¡CONTACTA HOY!</a>
            </div>
            <!-- /.container -->
        </div>
        <!-- /.camera_caption -->
    </div>
    <div data-src="assets/images/home/top-4.jpg">
        <div class="camera_caption">
            <div class="container">
                <p class="wow fadeInUp animated">EFICIENCIA, AHORRO Y</p>
                <h1 class="wow fadeInUp animated" data-wow-delay="0.2s">PRECISIÓN ES <br> NUESTRA GESTIÓN </h1>
                <a routerLink="/home" fragment="contacto" class="theme-button-one wow fadeInUp animated" data-wow-delay="0.39s">¡CONTACTA HOY!</a>
            </div>
            <!-- /.container -->
        </div>
        <!-- /.camera_caption -->
    </div>
    <div data-src="assets/images/home/top-5.jpg">
        <div class="camera_caption">
            <div class="container">
                <p class="wow fadeInUp animated">PROVISIÓN, GESTIÓN Y DESARROLLO DE</p>
                <h1 class="wow fadeInUp animated" data-wow-delay="0.2s">TALENTO A <br> TU ALCANCE</h1>
                <a routerLink="/home" fragment="contacto" class="theme-button-one wow fadeInUp animated" data-wow-delay="0.39s">¡CONTACTA HOY!</a>
            </div>
            <!-- /.container -->
        </div>
        <!-- /.camera_caption -->
    </div>
</div>
<!-- /#theme-main-banner -->

<!-- 
    =============================================
                Top Feature
    ============================================== 
-->
<div class="top-feature section-spacing">
    <div class="top-features-slide">
        <div class="item">
            <div class="main-content" style="background:#fafafa;">
                <img src="assets/images/icon/4.png" alt="">
                <h4><a class="disable-link" href="#">Experiencia</a></h4>
                <p>Más de 25 años de experiencia en el mercado mexicano</p>
            </div>
            <!-- /.main-content -->
        </div>
        <!-- /.item -->
        <div class="item">
            <div class="main-content" style="background:#f6f6f6;">
                <img src="assets/images/icon/8.png" alt="">
                <h4><a class="disable-link" href="#">Entendimiento</a></h4>
                <p>Escucha activa de las necesidades de tu negocio o empresa</p>
            </div>
            <!-- /.main-content -->
        </div>
        <!-- /.item -->
        <div class="item">
            <div class="main-content" style="background:#efefef;">
                <img src="assets/images/icon/1.png" alt="">
                <h4><a class="disable-link" href="#">Cercanía</a></h4>
                <p>Atención personalizada a nuestros clientes y colaboradores</p>
            </div>
            <!-- /.main-content -->
        </div>
        <!-- /.item -->
        <div class="item">
            <div class="main-content" style="background:#ebebeb;">
                <img src="assets/images/icon/10.png" alt="">
                <h4><a class="disable-link" href="#">Innovación</a></h4>
                <p>Adaptabilidad y búsqueda continua de innovación</p>
            </div>
            <!-- /.main-content -->
        </div>
        <!-- /.item -->
        <div class="item">
            <div class="main-content" style="background:#e7e7e7;">
                <img src="assets/images/icon/3.png" alt="">
                <h4><a class="disable-link" href="#">Inclusión</a></h4>
                <p>Somos una empresa totalmente incluyente</p>
            </div>
            <!-- /.main-content -->
        </div>
    </div>
    <!-- /.top-features-slide -->
</div>
<!-- /.top-feature -->


<!-- 
    =============================================
                    About Company
    ============================================== 
-->
<div class="about-compnay section-spacing">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12"><img src="assets/images/home/about-2.jpg" alt=""></div>
            <div class="col-lg-6 col-12">
                <div class="text">
                    <div class="theme-title-one">
                        <h2>Acerca de Nosotros</h2>
                        <p>
                            Somos tu solución de recursos humanos, para que te enfoques en tu negocio mientras nosotros te acompañamos con el personal.
                        </p>
                        <p>
                            Aquí encontrarás las mejores soluciones de recursos humanos para cubrir las necesidades de tu empresa.
                        </p>
                    </div>
                    <!-- /.theme-title-one -->
                    <ul class="mission-goal clearfix">
                        <li>
                            <i class="icon flaticon-puzzle"></i>
                            <h4>Análisis</h4>

                        </li>
                        <li>
                            <i class="icon flaticon-presentation"></i>
                            <h4>Estrategias</h4>
                        </li>
                        <li>
                            <i class="icon flaticon-medal"></i>
                            <h4>Resultados</h4>
                        </li>
                    </ul>
                    <!-- /.mission-goal -->
                </div>
                <!-- /.text -->
            </div>
            <!-- /.col- -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</div>
<!-- /.about-compnay -->


<!-- 
    =============================================
                    Feature Banner
    ============================================== 
-->
<div class="feature-banner">
    <div class="opacity">
        <div class="container">
            <h2>Garantiza el Crecimiento Rentable de tu Negocio</h2>
            <a routerLink="/home" fragment="contacto" class="theme-button-one">¡Quiero Comenzar Hoy!</a>
        </div>
        <!-- /.container -->
    </div>
    <!-- /.opacity -->
</div>
<!-- /.feature-banner -->



<!-- 
    =============================================
                    Our Solution
    ============================================== 
-->
<div id="soluciones" class="our-solution section-spacing-top pb-5">
    <div class="container">
        <div class="theme-title-one">
            <h2>SOLUCIONES</h2>
            <p style="margin:0 35px 0 35px;">
                Para que te enfoques en tu Negocio, nosotros nos encargamos del Recurso Humano,<br> optimizando tus resultados mediante Selección Correcta, Seguimiento Continuo y Capacitación Asertiva.
            </p>
        </div>
        <!-- /.theme-title-one -->
        <div class="wrapper">
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="single-solution-block">
                        <a (click)="openModal('service-1')">
                            <img src="assets/images/icon/head-hunting-ok.png" alt="" class="icon">
                            <h5>
                                Head Hunting
                            </h5>
                            <p>Porque el mejor talento para tu empresa, lo encontramos nosotros </p>
                        </a>
                    </div>
                    <!-- /.single-solution-block -->
                </div>
                <!-- /.col- -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="single-solution-block">
                        <a (click)="openModal('service-2')">
                            <img src="assets/images/icon/seleccion-ok.png" alt="" class="icon">
                            <h5>Reclutamiento y Selección</h5>
                            <p>El Candidato Correcto en el Puesto Correcto </p>
                        </a>
                    </div>
                    <!-- /.single-solution-block -->
                </div>
                <!-- /.col- -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="single-solution-block">
                        <a (click)="openModal('service-3')">
                            <img src="assets/images/icon/maquila-nomina-ok.png" alt="" class="icon">
                            <h5>
                                Maquila de Nómina
                            </h5>
                            <p>Cálculos correctos para la satisfacción de tu Capital Humano </p>
                        </a>
                    </div>
                    <!-- /.single-solution-block -->
                </div>
                <!-- /.col- -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="single-solution-block">
                        <a (click)="openModal('service-4')">
                            <img src="assets/images/icon/tercerizacion-ok.png" alt="" class="icon">
                            <h5>Tercerización de Personal</h5>
                            <p>
                                Para que te enfoques en tus resultados, nosotros gestionamos tu personal
                            </p>
                        </a>
                    </div>
                    <!-- /.single-solution-block -->
                </div>
                <!-- /.col- -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="single-solution-block">
                        <a (click)="openModal('service-5')">
                            <img src="assets/images/icon/admin-on-site-ok.png" alt="" class="icon">
                            <h5>Administración On-Site</h5>
                            <p>Cercanía y atención contigo y tu personal </p>
                        </a>
                    </div>
                    <!-- /.single-solution-block -->
                </div>
                <!-- /.col- -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="single-solution-block">
                        <a (click)="openModal('service-6')">
                            <img src="assets/images/icon/capacitacion-ok.png" alt="" class="icon">
                            <h5>Capacitación</h5>
                            <p>El conocimiento implica cambio continuo, invierte en tu personal para alcanzar el éxito en el futuro </p>
                        </a>
                    </div>
                    <!-- /.single-solution-block -->
                </div>
                <!-- /.col- -->

            </div>
            <!-- /.row -->

        </div>
        <!-- /.wrapper -->
        <!-- The Modal -->
        <div *ngFor="let modal of modals" id="{{modal.id}}" class="modal-funcion">
            <!-- Modal content -->
            <div class="modal-content-funcion animated">
                <div class="row justify-content-center">
                    <div class="col-10 pb-0 text-center">
                        <h3 style="color: #406C98;"><strong>{{modal.title}}</strong></h3>
                    </div>

                    <span class="close" (click)="closeModal()">&times;</span>

                </div>
                <div class="row">
                    <div class="col-12 pt-2 pb-3 text-center">
                        <p style="color: #406C98;"> {{modal.descripcion}}</p>
                    </div>
                </div>
                <div class="row" *ngFor="let funcionalidad of modal.funcionalidades">
                    <div class="col-12">

                        <p class="" style="margin: 0 !important; padding: 0px !important;">- {{funcionalidad}}</p>
                        <hr class="horizontal-funcionalidad">
                        <div *ngIf="funcionalidad==='Perfiles Especializados'">
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-3">
                        <a routerLink="/home" fragment="contacto" (click)="closeModal();" style="display: block; margin: 0 auto;" type="button" class="btn btn-secondary">Más Información</a>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <!-- /.container -->
</div>
<!-- /.our-solution -->


<!-- 
    =============================================
                    PROCESO
    ============================================== 
-->
<div id="proceso" class="our-process section-spacing-top pb-5">
    <div class="container">
        <div class="theme-title-one">
            <h2>¿CÓMO LO HACEMOS?</h2>
            <p style="margin:0 35px 40px 35px;">
                Nuestra metodología nos ayuda a entender mejor tus necesidades y a darte una solución precisa para ayudarte a alcanzar tus objetivos.
            </p>
        </div>
        <!-- /.theme-title-one -->
        <div class="wrapper">

            <div class="row carrusel-active">

                <div class="col-xl-2 col-md-4 col-12 pt-3 image">
                    <img class="img-fluid" src="assets/images/proceso/Infografia_01.png">
                </div>
                <!-- /.col- -->
                <div class="col-xl-2 col-md-4 col-12 pt-3 image">
                    <img class="img-fluid" src="assets/images/proceso/Infografia_02.png">
                </div>
                <!-- /.col- -->
                <div class="col-xl-2 col-md-4 col-12 pt-3 image">
                    <img class="img-fluid" src="assets/images/proceso/Infografia_03.png">
                </div>
                <!-- /.col- -->
                <div class="col-xl-2 col-md-4 col-12 pt-3 image">
                    <img class="img-fluid" src="assets/images/proceso/Infografia_04.png">
                </div>
                <!-- /.col- -->
                <div class="col-xl-2 col-md-4 col-12 pt-3 image">
                    <img class="img-fluid" src="assets/images/proceso/Infografia_05.png">
                </div>
                <!-- /.col- -->
                <div class="col-xl-2 col-md-4 col-12 pt-3 image">
                    <img class="img-fluid" src="assets/images/proceso/Infografia_06.png">
                </div>
                <!-- /.col- -->

                <!-- /.row -->
            </div>

        </div>
    </div>
    <!-- /.container -->
</div>
<!-- /.our-solution -->


<!--
    =====================================================
                    Testimonial Slider
    =====================================================
-->
<div class="testimonial-section">
    <div class="overlay">
        <div class="container">
            <div class="wrapper">
                <div class="bg">
                    <h2 class="pb-3">Casos de Éxito</h2>
                    <div class="testimonial-slider">
                        <div class="item">
                            <p>“ Como clientes por más de 5 años de Coca Consultores destacaríamos su profesionalismo en todos los servicios que ofrecen; además resaltaría la amplia experiencia del equipo y su compromiso con los clientes para buscar siempre
                                la mejor solución. ”
                            </p>
                            <div class="name">
                                <h6>Erik Castillo</h6>
                                <span>Director General, VCP Tecnología.</span>
                            </div>
                            <!-- /.name -->
                        </div>
                        <!-- /.item -->
                        <div class="item">
                            <p>“ Desde que comencé a llevar mi contabilidad personal como la de CECIG con Coca Consultores, comencé a descansar. Tuve muchos problemas con la contabilidad anteriormente y tanto el trato personal como el manejo de la contabilidad,
                                el despacho ha sido de toda mi confianza. Muy agradecida por tener un despacho tan profesional. ”
                            </p>
                            <div class="name">
                                <h6>Susana Cruickshank</h6>
                                <span>Socia Fundadora, CECIG A.C.</span>
                            </div>
                            <!-- /.name -->
                        </div>
                        <!-- /.item -->
                        <div class="item">
                            <p>“Coca Consultores siempre está buscando agregar valor a nuestra relación, son muy conocedores de sus temas, por lo que son altamente recomendables. Más que proveedor puedes considerarlo como un aliado en tema contable y nóminas”
                            </p>
                            <div class="name">
                                <h6>Laura Faour</h6>
                                <span>Socia Fundadora, CALAUDIT</span>
                            </div>
                            <!-- /.name -->
                        </div>
                        <div class="item">
                            <p>“Creemos y estamos convencidos de que son de gran ayuda para que Simplifik pueda hacer las cosas bien, y crecer con mucho orden en el futuro”
                            </p>
                            <div class="name">
                                <h6>Gonzalo Rolnik</h6>
                                <span>Socio Fundador, SIMPLIFIK</span>
                            </div>
                            <!-- /.name -->
                        </div>
                        <div class="item">
                            <p>
                                “Después de más de 18 años de trabajar con Coca Consultores, puedo decir que además del apoyo con la contabilidad y asesoría fiscal en 6 Empresas del Grupo, hemos forjado una extraordinaria relación profesional, siempre están disponibles para ayudarte
                                y llevan un excelente control en todos los ámbitos de su área de responsabilidad”
                            </p>
                            <div class="name">
                                <h6>Federico Castillo</h6>
                                <span>OETESA</span>
                            </div>
                            <!-- /.name -->
                        </div>

                    </div>
                    <!-- /.testimonial-slider -->
                </div>
                <!-- /.bg -->
            </div>
            <!-- /.wrapper -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.overlay -->
</div>
<!-- /.testimonial-section -->


<!-- 
			=============================================
				Capacitaciones
			============================================== 
		-->
<div id="capacitaciones" class="core-values-spacing service-style-one">
    <div class="container pt-4">
        <div class="theme-title-one">
            <h2>CAPACITACIONES</h2>
            <p>El conocimiento implica cambio continuo; invierte en tu personal para alcanzar el éxito en el futuro.</p>
        </div>
        <div class="text-center pt-3">
            <a href="https://cocaconsultores.com/capacitacion/" target="_blank" class="theme-button-one text-white">Ver Todas las Capacitaciones</a>
        </div>
        <!-- /.theme-title-one -->
        <div class="wrapper">
            <div class="core-value-slider">
                <div class="item">
                    <div class="single-value-block">
                        <div class="image-box">
                            <img src="assets/images/home/finanzas.jpg" alt="">
                            <div class="overlay">
                                <a href="https://cocaconsultores.com/capacitacion/finanzas.html" target="_blank" class="theme-button-one">SABER MÁS</a></div>
                        </div>
                        <!-- /.image-box -->
                        <div class="text">
                            <h5><a href="https://cocaconsultores.com/capacitacion/finanzas.html" target="_blank">Finanzas</a></h5>
                            <p style="font-size: 16px;" class="text-justify">Para que tú y tu personal logren afrontar de manera exitosa y efectiva el mercado cambiante, asumiendo riesgos medidos y controlados, nosotros los preparamos siempre con vanguardia.</p>
                        </div>
                        <!-- /.text -->
                    </div>
                    <!-- /.single-value-block -->
                </div>
                <!-- /.col- -->
                <div class="item">
                    <div class="single-value-block">
                        <div class="image-box">
                            <img src="assets/images/home/operacion-2.jpg" alt="">
                            <div class="overlay"><a href="https://cocaconsultores.com/capacitacion/operaciones.html" target="_blank" class="theme-button-one">SABER MÁS</a></div>
                        </div>
                        <!-- /.image-box -->
                        <div class="text">
                            <h5><a href="https://cocaconsultores.com/capacitacion/operaciones.html" target="_blank">Operaciones</a></h5>
                            <p style="font-size: 16px;" class="text-justify">Sabemos que tu día a día es donde está la operación de tu negocio; dale a tu equipo el soporte, el conocimiento técnico y lo nuevo que hay en el mercado para que vayan siempre un paso adelante.</p>
                        </div>
                        <!-- /.text -->
                    </div>
                    <!-- /.single-value-block -->
                </div>
                <!-- /.col- -->
                <div class="item">
                    <div class="single-value-block">
                        <div class="image-box">
                            <img src="assets/images/home/capital_humano.jpg" alt="">
                            <div class="overlay"><a href="https://cocaconsultores.com/capacitacion/rechum.html" target="_blank" class="theme-button-one">SABER MÁS</a></div>
                        </div>
                        <!-- /.image-box -->
                        <div class="text">
                            <h5><a href="https://cocaconsultores.com/capacitacion/rechum.html" target="_blank">Capital Humano</a></h5>
                            <p style="font-size: 16px;" class="text-justify">Porque la base de todo negocio son sus equipos de trabajo, te ayudamos a potencializar a tu equipo de gestión para generar empoderamiento, madurez, liderazgo y sobre todo, un equipo de alto rendimiento.</p>
                        </div>
                        <!-- /.text -->
                    </div>
                    <!-- /.single-value-block -->
                </div>
                <!-- /.col- -->
                <div class="item">
                    <div class="single-value-block">
                        <div class="image-box">
                            <img src="assets/images/home/marketing.jpg" alt="">
                            <div class="overlay"><a href="https://cocaconsultores.com/capacitacion/ventas.html" target="_blank" class="theme-button-one">SABER MÁS</a></div>
                        </div>
                        <!-- /.image-box -->
                        <div class="text">
                            <h5><a href="https://cocaconsultores.com/capacitacion/ventas.html" target="_blank">Marketing - Ventas</a></h5>
                            <p style="font-size: 16px;" class="text-justify">Vanguardia y tecnología hacen hoy la diferencia en este rubro. Prepara a tu personal para tener el mejor equipo de ventas, que aporten tu diferencial en el mercado. Juega a ganar con nosotros como aliados.</p>
                        </div>
                        <!-- /.text -->
                    </div>
                    <!-- /.single-value-block -->
                </div>
            </div>
            <!-- /.core-value-slider -->

        </div>
        <!-- /.wrapper -->
        <div class="contact-text mt-5">
            <h4>Puedes enviarnos un email y nos pondremos en contacto contigo, o llámanos</h4>
            <h5 class="correo">
                contacto@cocaconsultores.com
            </h5><br><br>
            <h5><a href="tel:5524542174">+52 (55) 2454 2174</a></h5>

        </div>
    </div>
    <!-- /.container -->
</div>
<!-- /.core-values -->

<!--
    =====================================================
                    Partner Slider
    =====================================================
-->
<div class="partner-section bg-color">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-4 col-12">
                <h6 style="margin-top: 15% !important;">NUESTROS <br>PARTNERS</h6>
            </div>
            <div class="col-md-9 col-sm-8 col-12">
                <div class="partner-slider">
                    <div class="item">
                        <img src="assets/images/logo/veyco.png" alt=""></div>
                    <div class="item">
                        <a href="https://hcinteligencia.com.mx/" target="_blank">
                            <img src="assets/images/logo/hc.png" alt="">
                        </a>
                    </div>
                    <div class="item">
                        <a href="https://cocaconsultinggroup.com/" target="_blank">
                            <img src="assets/images/logo/cocagroup.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.partner-section -->


<!-- 
    =============================================
                CONTACTANOS
    ============================================== 
-->
<div id="contacto" class="about-compnay-two">
    <div class="overlay">
        <div class="container pt-4 pb-4">
            <div class="row no-gutters">
                <div class="col-lg-7 col-12 text text-center pt-3">
                    <div class="theme-title-one">
                        <h2>CONTÁCTANOS</h2>
                    </div>
                    <!-- /.theme-title-one -->
                    <h4 class="pt-3 pb-2 text-white">Oficina:</h4>
                    <p class="text-white"> <a href="tel:5524542174" style="text-decoration: none; color: #FFFFFF">+52 5524542174</a></p>
                    <p class="pb-4 text-white"><a href="tel:5524542175" style="text-decoration: none; color: #FFFFFF">+52 5524542175</a></p>

                    <h4 class="pt-3 pb-2 text-white">Email:</h4>
                    <p class="text-white">contacto@cocaconsultores.com</p>

                </div>
                <!-- /.col- -->
                <div class="col-lg-5 col-12">
                    <div class="quote-form">
                        <form [formGroup]="contactForm" (ngSubmit)="formSubmit(contactForm.value)" class="theme-form-one">
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" formControlName="nombre" placeholder="Nombre *">
                                    <div *ngIf="contactForm.controls['nombre'].invalid && contactForm.controls['nombre'].touched" class="alert alert-danger">
                                        <div *ngIf="contactForm.controls['nombre'].errors.required">
                                            Nombre es requerido.
                                        </div>
                                        <div *ngIf="contactForm.controls['nombre'].errors.maxlength">
                                            Has excedido el tamaño.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" formControlName="whatsapp" placeholder="Whatsapp *">
                                    <div *ngIf="contactForm.controls['whatsapp'].invalid && contactForm.controls['whatsapp'].touched" class="alert alert-danger">
                                        <div *ngIf="contactForm.controls['whatsapp'].errors.required">
                                            Whatsapp es requerido.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="email" formControlName="email" placeholder="Email *">
                                    <div *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched" class="alert alert-danger">
                                        <div *ngIf="contactForm.controls['email'].errors.required">
                                            Email es requerido.
                                        </div>
                                        <div *ngIf="contactForm.controls['email'].errors.email">
                                            Email debe tener formato válido.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control" formControlName="servicio" id="exampleSelect1">
                                                      <option [ngValue]="null" disabled>¿Servicio de Interés?</option>
                                                      <option value="Head Hunting">Head Hunting</option>
                                                      <option value="Reclutamiento y Selección">Reclutamiento y Selección</option>
                                                      <option value="Maquila de Nómina">Maquila de Nómina</option>
                                                      <option value="Tercerización de Personal">Tercerización de Personal</option>
                                                      <option value="Administración On Site">Administración On Site</option>
                                                      <option value="Capacitación">Capacitación</option>
                                    </select>
                                    <div *ngIf="contactForm.controls['servicio'].invalid && contactForm.controls['servicio'].touched" class="alert alert-danger">
                                        <div *ngIf="contactForm.controls['servicio'].errors.required">
                                            Servicio es requerido.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <textarea formControlName="mensaje" placeholder="Mensaje"></textarea>
                                    <div *ngIf="contactForm.controls['mensaje'].invalid && contactForm.controls['mensaje'].touched" class="alert alert-danger">
                                        <div *ngIf="contactForm.controls['mensaje'].errors.required">
                                            Mensaje es requerido.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /.row -->
                            <button type="submit" [disabled]="!contactForm.valid" class="theme-button-one">ENVIAR</button>
                        </form>
                    </div>
                    <!-- /.quote-form -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.overlay -->
</div>
<!-- /.about-compnay-two -->


<!-- 
    =============================================
               SIGUENOS EN REDES
    ============================================== 
-->

<div id="proceso" class="social-networks section-spacing-top pb-5">
    <div class="container">
        <div class="theme-title-one">
            <h2 style="">SÍGUENOS EN REDES</h2>
            <p style="margin:0 35px 40px 35px;">
                Nos encontramos activos en las redes sociales para brindar más confianza, aportando información de valor.
            </p>
        </div>
        <!-- /.theme-title-one -->
        <div class="wrapper">

            <div class="row justify-content-center">
                <div class="col-xl-4 col-md-4 col-12">
                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCoca-Consultores-102537535188470&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1072847496127761"
                        width="100%" height="400" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
                    </iframe>
                </div>
                <!-- /.col- -->
                <div class="col-xl-4 col-md-4 col-12 text-center">
                    <a class="twitter-timeline twitter" height="400" href="https://twitter.com/CocaConsultores?ref_src=twsrc%5Etfw">Tweets by CocaConsultores</a>
                </div>

                <!-- /.row -->
            </div>

        </div>
    </div>
    <!-- /.container -->
</div>
<!-- /.our-solution -->

<!--
    =====================================================
                    Google Map
    =====================================================
-->




<!-- Google Map -->
<div class="google-map-two">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.2801269479874!2d-99.2053303!3d19.357019599999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2001669a74fed%3A0xc6cca4fe4df120ea!2zTcOpZGFub3MgMTM3LCBQaWxhcmVzIMOBZ3VpbGFzLCDDgWx2YXJvIE9icmVnw7NuLCAwMTcxMCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWA!5e0!3m2!1ses!2smx!4v1659565502784!5m2!1ses!2smx"
        width="100%" height="410" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>

<app-footer></app-footer>