<app-header-other-pages id="top"></app-header-other-pages>
<!--
	=====================================================
				Why We Best
	=====================================================
-->
<div class="why-we-best" id="top">
    <div class="overlay">
        <div class="container">
            <div class="theme-title-one">
                <h2>¿QUIÉNES SOMOS?</h2>
                <p>
                    Empresa 100% Mexicana, con más de 25 años en el mercado. Expertos en la Gestión del Capital Humano.
                </p>
                <p>
                    Ofrecemos soluciones de Recursos Humanos para acompañar a las empresas y al talento humano a alcanzar los objetivos estratégicos y de vida, ya sean temporales o permanentes; en reclutamiento, selección, administración y su capacitación.
                </p>
            </div>
            <!-- /.theme-title-one -->

            <div class="wrapper row no-gutters">
                <div class="col-lg-6 col-12 order-lg-last">
                    <div class="img-box"></div>
                </div>
                <div class="col-lg-6 col-12 order-lg-first">
                    <ul class="best-list-item">
                        <li>
                            <i class="icon flaticon-interface"></i>
                            <h5><a href="#">Misión</a></h5>
                            <p class="text-justify mr-3">
                                Prestar servicios integrales de Terciarización de Personal para impactar positivamente en el bienestar social, a través de la generación de más fuentes de trabajo, contribuyendo al progreso social y beneficio de todos los que formamos parte del mismo.
                            </p>
                        </li>
                        <li>
                            <i class="icon flaticon-technology"></i>
                            <h5><a href="#">Visión</a></h5>
                            <p class="text-justify mr-3">
                                Ser una empresa Líder de Servicios de Capital Humano, en el Mercado Nacional, con un amplio sentido humano y de calidad. Consolidar el crecimiento de la marca a través de la mejora continua e innovación dentro de nuestros procesos que faciliten eficientar
                                resultados.
                            </p>
                        </li>
                        <li>
                            <i class="icon flaticon-star"></i>
                            <h5><a href="#">Valores</a></h5>
                            <p class="text-justify mr-3">
                                Honestidad, Compromiso, Adaptabilidad, Ética, Respeto, Inclusión y Responsabilidad Social.
                            </p>
                        </li>
                    </ul>
                </div>
                <!-- /.col- -->
            </div>
            <!-- /.wrapper -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.overlay -->
</div>
<!-- /.why-we-best -->

<!-- 
	=============================================
				Core Values
	============================================== 
-->
<div class="core-values">
    <div class="container">
        <div class="theme-title-one">
            <h2>NUESTRO EQUIPO</h2>
        </div>
        <!--
			=====================================================
				Our Team
			=====================================================
		-->
        <div class="our-team section-spacing">
            <div class="container">
                <div class="wrapper">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="team-member">
                                <div class="image-box">
                                    <img src="assets/images/team/foto-hugo.jpg" alt="">
                                    <div class="overlay">
                                        <div class="hover-content">
                                            <ul>
                                                <li><a href="https://www.linkedin.com/in/hugo-coca-chávez-32034b23" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                <li><a href="https://twitter.com/hugococa1/status/1354464199506866180?s=21" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            </ul>
                                            <p>“Hombre que siempre lucha por lo que quiere”.</p>
                                        </div>
                                        <!-- /.hover-content -->
                                    </div>
                                    <!-- /.overlay -->
                                </div>
                                <!-- /.image-box -->
                                <div class="text">
                                    <h6>Hugo Coca</h6>
                                    <span>Director General</span>
                                </div>
                                <!-- /.text -->
                            </div>
                            <!-- /.team-member -->
                        </div>
                        <!-- /.col- -->
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="team-member">
                                <div class="image-box">
                                    <img src="assets/images/team/foto-diana.jpg" alt="">
                                    <div class="overlay">
                                        <div class="hover-content">
                                            <ul>
                                                <li><a href="https://www.linkedin.com/in/diana-cahue-30b10738" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                            </ul>
                                            <p>“Valentía para ser, continuar y renovarse es la esencia de cualquier negocio y persona”.</p>
                                        </div>
                                        <!-- /.hover-content -->
                                    </div>
                                    <!-- /.overlay -->
                                </div>
                                <!-- /.image-box -->
                                <div class="text">
                                    <h6>Diana Cahue</h6>
                                    <span>Socio Director RH</span>
                                </div>
                                <!-- /.text -->
                            </div>
                            <!-- /.team-member -->
                        </div>
                        <!-- /.col- -->
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="team-member">
                                <div class="image-box">
                                    <img src="assets/images/team/foto-sebastian.jpg" alt="">
                                    <div class="overlay">
                                        <div class="hover-content">
                                            <ul>
                                                <li><a href="https://www.linkedin.com/in/fabiola-massagu%C3%A9-48a14532/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                            </ul>
                                            <p>“Es más fácil obtener lo que se desea con una sonrisa que con la punta de la espada (WShakespeare)”.</p>
                                        </div>
                                        <!-- /.hover-content -->
                                    </div>
                                    <!-- /.overlay -->
                                </div>
                                <!-- /.image-box -->
                                <div class="text">
                                    <h6>Sebastián Núñez</h6>
                                    <span>Comercial</span>
                                </div>
                                <!-- /.text -->
                            </div>
                            <!-- /.team-member -->
                        </div>
                        <!-- /.col- -->
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="team-member">
                                <div class="image-box">
                                    <img src="assets/images/team/foto-paulina.jpg" alt="">
                                    <div class="overlay">
                                        <div class="hover-content">
                                            <ul>
                                                <!--li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li-->
                                                <li><a href="http://linkedin.com/in/sebastián-núñez-781984243/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                            </ul>
                                            <p>“La edad no es una barrera, es una limitación que pones en tu mente”.</p>
                                        </div>
                                        <!-- /.hover-content -->
                                    </div>
                                    <!-- /.overlay -->
                                </div>
                                <!-- /.image-box -->
                                <div class="text">
                                    <h6>Paulina Padilla</h6>
                                    <span>Tesorería y Planeación Fiscal</span>
                                </div>
                                <!-- /.text -->
                            </div>
                            <!-- /.team-member -->
                        </div>
                        <!-- /.col- -->
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="team-member">
                                <div class="image-box">
                                    <img src="assets/images/team/foto-karen.jpg" alt="">
                                    <div class="overlay">
                                        <div class="hover-content">
                                            <!--ul>
                                                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                <li><a href="#"><i class="fa fa-pinterest" aria-hidden="true"></i></a></li>
                                            </ul-->
                                            <p>"El éxito en la vida no se mide por lo que logras sino por los obstáculos que superas".</p>
                                        </div>

                                    </div>

                                </div>

                                <div class="text">
                                    <h6>Karen Hernández</h6>
                                    <span>Contabilidad y Fiscal</span>
                                </div>

                            </div>
                        </div>

                        <!-- /.col- -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.wrapper -->
            </div>
            <!-- /.container -->
        </div>
        <!-- /.our-team -->

    </div>
    <!-- /.container -->
</div>
<!-- /.core-values -->

<!--
	=====================================================
				Partner Slider
	=====================================================
-->
<div class="partner-section">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-4 col-12">
                <h6 style="margin-top: 40px;">NUESTROS <br>PARTNERS</h6>
            </div>
            <div class="col-md-9 col-sm-8 col-12">

                <div class="partner-slider">
                    <div class="item"><img src="assets/images/logo/veyco.png" alt=""></div>
                    <div class="item"><img src="assets/images/logo/hc.png" alt=""></div>
                    <div class="item">
                        <a href="https://cocaconsultinggroup.com/" target="_blank">
                            <img src="assets/images/logo/cocagroup.png" alt="">
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- /.partner-section -->


<app-footer></app-footer>