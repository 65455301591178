<!--
			=====================================================
				Footer
			=====================================================
            -->


<!-- Modal -->
<div class="modal fade" id="privacidadModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">AVISO DE PRIVACIDAD</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <p><strong>COCA CONSULTORES, S.A. DE C.V.</strong> con domicilio en MEDANOS 137, COL. LAS AGUILAS, ALCALDIA ALVARO OBREGON, C.P. 01710, CIUDAD DE MEXICO, CP 04320, le informa que los datos personales que reciba deben ser tratados conforme al Aviso de Privacidad
                    Integral de
                    <strong>COCA CONSULTORES, S.A. DE C.V.</strong>, puesto a disposición del titular previo a la captación de los datos; asimismo la recepción de datos personales en <strong>COCA CONSULTORES, S.A. DE C.V.</strong>, se efectúa en el entendido
                    de que usted obtuvo previamente el consentimiento de los titulares de los mismos. </p>

                <p>El tratamiento de los datos personales se realiza para el cumplimiento de obligaciones, gestiones, proveerle de servicios, con fines de mercadotecnia, publicidad o prospección comercial y de conformidad con el Aviso de Privacidad Integral
                    que se encuentra disponible en la página de Internet <strong>www.cocaconsultores.com</strong> en la sección aviso de privacidad.</p>
                <br>
                <p>La información contenida en este mensaje es confidencial, restringida y está destinada únicamente para el uso de la persona a quien se dirige, si usted no es el destinatario legítimo del mismo, deberá reportarlo al remitente del correo
                    y borrarlo inmediatamente. </p>

                <p> Por lo tanto, queda prohibido su uso a persona ajena al destinatario indicado y/o para fines diferentes a los que se expresan en este mensaje. Por lo que, cualquier uso distinto al expresamente autorizado, o por persona distinta al destinatario,
                    está estrictamente prohibido; de tal forma que, <strong>COCA CONSULTORES, S.A. DE C.V.</strong>, no se hace responsable de dichos usos y se reserva cualquier tipo de acción legal que pudiera derivar contra quien, o quienes, resulten
                    responsables de su divulgación, reproducción o uso, sin previa autorización por escrito por parte de <strong>COCA CONSULTORES, S.A. DE C.V.</strong></p>
                <br>
                <p class="text-center"> Atentamente </p>
                <p class="text-center"> COCA CONSULTORES S.A. de C.V. </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" style="display:block; margin:0 auto;" data-dismiss="modal">Acepto</button>
            </div>
        </div>
    </div>
</div>
<footer class="theme-footer-one">
    <div class="top-footer">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-md-4 col-sm-6 col-10">
                    <img class="img-fluid" src="assets/images/home/coca_blanco_med.png">
                </div>
                <div class="col-md-10 pt-5 text-center text-white">
                    <p>
                        MEDANOS 137, COL. LAS AGUILAS, ALCALDIA ALVARO OBREGON, C.P. 01710, CIUDAD DE MEXICO
                    </p>
                </div>
                <div class="col-md-10 pt-3 text-center text-white">
                    <p>
                        <a href="#" data-toggle="modal" data-target="#privacidadModal">Aviso de Privacidad</a>
                    </p>
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.top-footer -->
    <div class="bottom-footer">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 col-12 text-center">
                    <p>&copy; Copyright 2021. Todos los Derechos Reservados.</p>
                </div>
                <!--div class="col-md-6 col-12">
                    <ul>
                        <li><a href="about.html">About</a></li>
                        <li><a href="service.html">Solutions</a></li>
                        <li><a href="#">FAQ’s</a></li>
                        <li><a href="contact.html">Contact</a></li>
                    </ul>
                </div-->
            </div>
        </div>
    </div>
    <!-- /.bottom-footer -->
</footer>
<!-- /.theme-footer -->